import styled from '@emotion/styled';
import { NextPage } from 'next';
import { BsTwitterX } from 'react-icons/bs';
import { FaFacebookF, FaLine, FaYoutube } from 'react-icons/fa';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { ISocialList } from 'src/interface/widgets';
import { controller } from 'src/switch-themes';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const { socials } = controller;

const SOCIAL_LIST: ISocialList[] = [
  {
    id: 1,
    icon: <FaFacebookF id='facebook' size={20} />,
    link: socials?.facebook,
    name: 'facebook',
    className: 'icon-facebook'
  },
  {
    id: 2,
    icon: <BsTwitterX id='x' size={20} />,
    link: socials?.twitter,
    name: 'x',
    className: 'icon-x'
  },


  {
    id: 4,
    icon: <FaYoutube id='youtube' size={20} />,
    link: socials?.youtube,
    name: 'youtube',
    className: 'icon-youtube'
  },
  {
    id: 5,
    icon: <FaLine id='line' size={20} />,
    link: socials?.line,
    name: 'line',
    className: 'icon-line'
  }
];

type Props = {
  showTitle?: boolean;
  title?: string;
  eventDataLayer?: IDataLayerGlobal;
};
const WidgetFollow: NextPage<Props> = ({ showTitle = true, title, eventDataLayer }) => {
  return (
    <>
      <WidgetWrapper className='widget-follow'>
        {showTitle && (
          <div className='tags-title'>
            <p>{title}</p>
          </div>
        )}
        <div className='tags-items'>
          {SOCIAL_LIST.map(
            (item: ISocialList, index: number) =>
              item?.link && (
                <a
                  tabIndex={-1}
                  target='_blank'
                  key={index}
                  href={item?.link}
                  rel='noopener nopopopener nofollow'
                  aria-label='social-share'
                  title='social-share'
                  // ! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      ...eventDataLayer,
                      data: {
                        title: item?.name,
                        heading: title,
                        index: index + 1,
                        ...eventDataLayer?.data
                      }
                    })
                  }
                >
                  {item?.icon}
                </a>
              )
          )}
        </div>
      </WidgetWrapper>
    </>
  );
};

const WidgetWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .tags-title {
    margin-top: 30px;
    width: 100%;
    position: relative;
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.color};
    border-bottom: 2px solid #888888;
    margin-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      width: 150px;
      max-width: 100%;
      height: 2px;
      background-color: var(--secondary-color);
    }
  }
  .tags-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    a {
      svg {
        vertical-align: middle;
        height: 30px;
        width: 30px;
        padding: 7px;
        border-radius: 100%;
        color: var(--tertiary-color);
        border: 1px solid var(--tertiary-color);
        /* background: var(--primary-gradient); */
        /* background-blend-mode: overlay, normal; */
        /* color: var(--text-color); */
        transition: 0.1ms all ease-in-out;
        @media (max-width: 475px) {
          height: 25px;
          width: 25px;
          padding: 6px;
        }
        &:hover {
          background-blend-mode: overlay, normal;
          background: var(--primary-gradient);
          color: var(--text-color);
        }
      }
    }
    @media (max-width: 767px) {
      gap: 5px;
      a svg {
        height: 40px;
        width: 40px;
        padding: 8px;
        margin: 10px 0;
      }
    }
  }
`;
export default WidgetFollow;
