import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';

import _ from 'lodash';
import Image from 'next/image';
import { NextRouter, useRouter } from 'next/router';
import WidgetFollow from 'src/components/widget/widget-follow';
import { APP_IMG, SHOW_ANALITYCS, SHOW_COMMEMORATE } from 'src/constants';
import { IMenu, IPartner } from 'src/interface/layout';
import { GET } from 'src/services';
import { controller } from 'src/switch-themes';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { splitRote, timestamp } from 'src/utils/helper';
const { name, address, image } = controller;

const Footer = ({ data }: any) => {
  const [footer, setFooter] = useState<string>('');
  const [partners, setPartner] = useState<IPartner[]>([]);
  const router: NextRouter = useRouter();

  useEffect(() => {
    (async () => {
      try {
        const resData: [string, IPartner[]] = await Promise.all([
          GET({ url: '/api/footer', resolvedUrl: 'components/Footer' }),
          GET({ url: '/api/v1.0/navigations/company-group', resolvedUrl: 'components/Footer' })
        ]);

        if (resData[0]) setFooter(resData[0]);
        if (resData[1]) setPartner(resData[1]);
      } catch (err: any) {
        console.error(`${timestamp()} ==========> MENU_ERROR : `, err.message);
      }
    })();
  }, []);
  const _listMenu: IMenu[] = data;
  const _listPartner: IPartner[] = partners;
  return (
    <FooterWrapper id='footer-wrapper' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
      <div className='footer-wrapper container'>
        <div className='footer-list-area container'>
          <div className='block-1'>
            <Image src={image.footerLogo} alt='logo' aria-label='logo' width={160} height={60} />
            {/* <img src={footerLogo} alt='logo' aria-label='logo' width='200' height='100%' /> */}

            {!_.isEmpty(Object.values(address)) && (
              <div className='footer-address'>
                <p className='footer-address-detail'> {Object.values(address).slice(0, 3).join(' ')} </p>
              </div>
            )}
          </div>
          <WidgetFollow
            showTitle={false}
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              section: 'footer',
              position: 'on:footer'
            }}
          />

          <div className='block-sonp'>
            <a
              href='http://www.sonp.or.th/'
              target='_blank'
              rel='noopener noreferrer'
              // ! DATALAYER
              onClick={() =>
                sendDataLayerGlobal({
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'footer',
                  position: 'on:footer',
                  data: {
                    title: 'http://www.sonp.or.th/',
                    heading: 'สมาคมผู้ผลิตข่าวออนไลน์'
                  }
                })
              }
            >
              <Image src={image.logoSnop || `${APP_IMG}/cache/logo_snop.webp`} alt='logo' className='footer-logo-sonp' height={80} width={240} />
            </a>
          </div>
          {!_.isEmpty(_listMenu) && (
            <div className='block-2'>
              <div className='footer-heading'>หมวดหมู่ข่าว</div>
              <ul className='footer-list'>
                {_listMenu.map((item: IMenu, index: number) => (
                  <li key={index} className='list-text'>
                    <a
                      href={`${splitRote(router)}/${item?.link}`}
                      title={item?.nameTh}
                      aria-label={item?.nameTh}
                      // ! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'footer',
                          position: 'on:footer',
                          data: {
                            title: item?.nameTh,
                            heading: 'หมวดหมู่ข่าว',
                            block: '2',
                            index: index + 1
                          }
                        })
                      }
                    >
                      {item?.nameTh}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!_.isEmpty(footer) && (
            <div className='block-3'>
              <p
                className='footer-content'
                dangerouslySetInnerHTML={{
                  __html: footer
                }}
              />
            </div>
          )}
        </div>
        {!_.isEmpty(_listPartner) && (
          <div className='block-partner container'>
            <ul className='footer-list'>
              {_listPartner.map((item: IPartner, index: number) => (
                <li key={index} className='list-text'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                    href={`${item?.link}`}
                    title={item?.nameEng}
                    aria-label={item?.nameEng}
                    // ! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_POSITION,
                        router: router?.pathname,
                        section: 'footer',
                        position: 'on:footer',
                        data: {
                          title: item?.nameEng,
                          heading: 'PARTNER',
                          index: index + 1
                        }
                      })
                    }
                  >
                    {item?.nameEng}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className='copyright-area'>
        <div className='container'>
          <div className='copyright-area-content'>
            <p>
              © {`${new Date().getFullYear()}`}
              <a
                href={`${splitRote(router)}/`}
                className='pl-1'
                // ! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    section: 'footer',
                    position: 'on:footer',
                    data: {
                      title: _.capitalize(name.en),
                      heading: 'copyright'
                    }
                  })
                }
              >
                {_.capitalize(name.en)}
              </a>
              All Rights Reserved.
              {SHOW_ANALITYCS && <span className='truehit' id='truehits_div' />}
            </p>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default memo(Footer);

const FooterWrapper = styled.footer`
  margin-top: 30px;
  background-color: #fff;
  padding: 16px;

  .footer-wrapper {
    background-color: var(--tertiary-color);
    font-family: var(--front-secondary), sans-serif;
    border-radius: 32px;
    .footer-list-area {
      padding: 30px 0 10px;
      display: grid;
      grid-template-columns: 1.4fr 1fr 1fr 2fr;
      grid-template-areas:
        'block1 block2 block2 block3'
        'widget-follow block-sonp block-sonp block3';
      @media (max-width: 768px) {
        grid-template-areas:
          'block1 block2 block2 block2'
          'widget-follow block3  block3 block3 '
          'block-sonp block-sonp block-sonp block-sonp';
      }
      @media (max-width: 690px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'block1 block1 block1 block1'
          'widget-follow widget-follow widget-follow widget-follow'
          'block2 block2 block2 block2'
          'block3 block3 block3 block3'
          'block-sonp block-sonp block-sonp block-sonp';
      }
    }
    .widget-follow {
      grid-area: widget-follow;
      .tags-items {
        display: flex;
        justify-content: flex-start;
        padding: 10px 10px;
        a svg {
          color: var(--black-color);
          border-color: var(--black-color);
        }
      }
    }
    .block-1 {
      grid-area: block1;
      padding: 0 15px;
      @media (max-width: 768px) {
        padding: 0px 15px 30px;
      }

      .footer-address {
        margin-top: 20px;
        color: var(--black-color);
        opacity: 0.85;
        p {
          color: var(--black-color);
          opacity: 0.85;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.45;
        }
      }
    }
    .block-2 {
      grid-area: block2;
      padding: 0 15px;
      @media (max-width: 768px) {
        padding: 10px 15px 30px;
      }
      .footer-heading {
        width: max-content;
        border-bottom: 1px solid var(--black-color);
        /* margin: 20px 0 30px; */
        color: var(--black-color);
        opacity: 0.85;
        font-size: 18px;
        font-weight: 700;
        @media (max-width: 690px) {
          margin: 30px 0 30px;
        }
      }
      .footer-list {
        column-count: 3;
        column-gap: 10px;
        @media (max-width: 690px) {
          column-count: 2;
        }

        .list-text {
          color: var(--black-color);
          opacity: 0.85;
          margin: 10px 0;
          a {
            color: var(--black-color);
            opacity: 0.85;
            padding: 0.8em 0.8em 0.8em 0;

            line-height: 1.45;
          }
          @media (max-width: 768px) {
            a {
              padding: 1.5em 1.5em 1em 0;
            }
          }
          &:hover a {
            color: var(--quaternary-color);
          }
        }
      }
    }
    .block-3 {
      grid-area: block3;
      padding: 0 15px;
      /* margin: 8px 0 30px; */
      color: var(--black-color);
      opacity: 0.85;
      line-height: 1.45;
      @media (max-width: 690px) {
        margin: 20px 0 30px;
      }
      .footer-content {
        background-color: var(--quaternary-color);
        /* background-color: #be4604; */
        padding: 20px;
        border-radius: 28px;
        font-size: 14px;

        strong {
          color: var(--black-color);

          font-size: 16px;
        }
        p {
          color: var(--black-color);

          font-weight: 300;
        }
      }
    }
    .block-sonp {
      grid-area: block-sonp;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      opacity: 0.85;
      @media (max-width: 960px) {
        text-align: center;
      }
      img {
        max-height: 60px;
      }
    }
    .block-partner {
      grid-area: block-partner;

      ul {
        /* border-top: 1px solid var(--white-color); */
        border-top: 1px solid var(--black-color);
        list-style-type: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 10px 15px 30px;
        li {
          font-size: 16px;
          font-weight: 200;
          line-height: 1.45;

          display: inline-block;
          a {
            color: var(--black-color);

            /* color: var(--white-color); */
            opacity: 0.85;
            text-transform: capitalize;
          }
          &:hover a {
            color: var(--quaternary-color);
          }
        }
      }
    }
  }
  .copyright-area {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: var(--white-color);
    border-top: 1px solid var(--white-color);
    margin: 0;

    .copyright-area-content {
      text-align: center;

      p {
        font-weight: 400;
        color: var(--gray-color);
        font-size: 12px;
        letter-spacing: 1px;
        margin: 0;
        a {
          display: inline-block;
          font-weight: 900;
          color: var(--black-color);
          /* color: var(--tertiary-color); */
          margin: 0 4px;

          &:hover {
            color: var(--tertiary-color);
          }
        }
      }
    }
  }
`;
